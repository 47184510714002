.Post-form h1{
    padding: 5px;
    font-family: "ubuntu";
    font-style: oblique;
    color: white;
    text-align: center;
    padding-bottom: 1.5%;

}

.Post-form {
    display: flex;
    margin-top: 1%;
    justify-content: center;
    align-items: center;

}
.Post-form p {
    text-align: center;
    align-items: center;
    top: 40%;
    font-family: "Ubuntu", sans-serif;
    font-style: oblique;
    position: absolute;
}


.Post-Input{
    position: relative;
    top: 50%;
    left: 50%;
    width: 350px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translate(-50%, -30%);
    background: #fff;
    transition: all 0.3s ease;
    text-align: center;
  }



.btn {
    display: flex;
    position: absolute;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    align-items: center;
    }
       
.btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #54a7c0;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
    
    }
       
.btn span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #ffffff;
    font-style: oblique;
    
    }
       
.btn svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #ffffff;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
    
    }
       
.btn:hover:before {
    width: 100%;
    background: #84a6b1;
    }
    
    .btn:hover svg {
    transform: translateX(0);
    }
    
    .btn:active {
    transform: scale(0.95);
    }

::-webkit-input-placeholder {
    text-align: center;
    font-size: 100%;
    text-transform: uppercase;
}
    :-moz-placeholder { /* Firefox 18- */
    text-align: center;  
    
    }
    
    ::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
    }
    
    :-ms-input-placeholder {  
    text-align: center; 
    }
    

.Ipmg{
    animation: Ip-anim infinite 0.6s alternate;
    height: 70px;
    display: flex;
    margin-left: 40%;
    margin-bottom: 2%;
    text-align: center;
    justify-content: center;
    align-items: center;
    }
@keyframes Ip-anim{
    from { transform: translateY(0%); }
    to { transform: translateY(15%) }
    }


.result {

    /* background-color: red; */

    max-width: 500px;
    width: 100%;
    height: auto;
    padding-top: 5%;
    margin: 0 auto;

}

@media screen and (max-width: 500px) {


    .result{
  
        padding-top: 15%;
        width: 400px;
        margin: 0 auto;
  
    }
    
  }
