
.Final-logo {
  text-align: center;
}

.App-logo {

  animation: App-logo-spin infinite 20s linear;
  height: 70px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.App-header {

  height: 150px;
  top: 0%;
  color: white;

}


.HowH1{
  position: relative;
  font-family: "ubuntu";
  top: 28%;  
  color: white;
  width: 24em;
  margin: 0 auto;
  font-size: 150%;
  text-align: center;

}

.HowContainer {

  border-radius: 2.5%;
  box-shadow: 14px 15px 5px 1px rgba(0, 0, 0, 0.356);

}

.link-secondary {

  padding: 2%;

}
.observation{

  position: relative;
  font-family: "ubuntu";
  font-size: medium;  
  width: 24em;
  margin: 0 auto;
  text-align: center;

}