.Post-form h1{
    font-family: "ubuntu";
    font-style: oblique;
    color: white;
    text-align: center;
    padding-bottom: 1.5%;

}

.Stepper-text-date {

    fill: white !important;
    color: lightskyblue;
    font-family: "ubuntu";
    font-style: oblique;
    word-wrap: break-word;
    margin: 0 auto;

}

.Stepper-text-time {
    
    fill: white !important;
    color: lightskyblue;
    font-family: "ubuntu";
    font-style: oblique;
    word-wrap: break-word;
    

}

.Stepper-text-status{

    fill: white !important;
    color: white;
    font-family: "ubuntu";
    word-wrap: break-word;
    margin: 0 auto;
    

}

.Stepper-css{

    margin: 0 auto;
    margin-top: 5%;
    
    width: 100%;
    height: auto;
    max-width: 1200px;

}

@media screen and (max-width: 500px) {
    
    .Stepper-css{

        padding-top: 10%;
        width: 100%;
        height: auto;

    }

}

.Post-form {
    
    margin-top: 1%;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 0px;

}
.Post-form p {
    text-align: center;
    align-items: center;
    top: 40%;
    font-family: "Ubuntu", sans-serif;
    font-style: oblique;
    position: absolute;
}

.Post-Input{
    position: relative;
    top: 50%;
    left: 50%;
    width: 350px;
    height: 40px;
    border-radius: 40px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translate(-50%, -30%);
    background: #fff;
    transition: all 0.3s ease;
    text-align: center;
  }



.search {
    display: flex;
    position: absolute;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    align-items: center;
    }
       
.search:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #54a7c0;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
    
    }
       
.search span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #ffffff;
    font-style: oblique;
    
    }
       
.search svg {

    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #ffffff;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
    
    }
       
.search:hover:before {
    width: 100%;
    background: #84a6b1;
    }
    
    .search:hover svg {
    transform: translateX(0);
    }
    
    .search:active {
    transform: scale(0.95);
    }

::-webkit-input-placeholder {
    text-align: center;
    font-size: 100%;
    text-transform: uppercase;
}
    :-moz-placeholder { /* Firefox 18- */
    text-align: center;  
    
    }
    
    ::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
    }
    
    :-ms-input-placeholder {  
    text-align: center; 
    }

.Cor {
    animation: Cor-anim infinite 2.2s alternate;
    height: 80px;
    display: flex;
    margin-left: 40%;
    text-align: center;
    justify-content: center;
    align-items: center;
    }
@keyframes Cor-anim{
    from { transform: translate(-50%); }
    to { transform: translate(50%); }
    }

