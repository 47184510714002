body {

  background: -webkit-linear-gradient(to left, #282a36, #282a36);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #282a36, #282a36); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.Final-logo {
  text-align: center;
}


.sliderimg{


  border-radius: 15px;
  box-shadow: 14px 15px 5px 1px rgba(0, 0, 0, 0.356);
  width: 100%;
  height: auto;
  max-width: 200px;
  margin: 0 auto;

}

.response {

  width: 100%;
  height: auto;
  max-width: 200px;
  padding-top: 3.5%;
  margin: 0 auto;

}

@media screen and (max-width: 500px) {


  .response{

    padding-top: 12%;

  }
  
}


.sliderprojects{

  width: 90%;
  border-radius: 15px;
  padding-left: 10%;
  padding-top: 5%;
  
}

.container {
  
  border-radius: 15px;
  box-shadow: 15px 15px 5px 1px rgba(0, 0, 0, 0.356);
  background:rgba(46, 45, 45, 0.568);
  
}

.Projects {

  padding-top: 1%;
  padding-bottom: 5%;
  border-radius: 20px;
  
}

.Projects h1{

  color: white;
  text-align: center;
  font-family: "ubuntu";

}
.Projects p{

  color: white;
  text-align: center;
  font-family: "ubuntu";

}

.App-logo {

  animation: App-logo-spin infinite 20s linear;
  height: 70px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.App-header {

  height: 150px;
  top: 0%;
  color: white;

}

.line-1{
  position: relative;
  font-family: Courier, monospace;
  top: 28%;  
  color: white;
  width: 24em;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  font-size: 2vw;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);    
}

@media screen and (max-width: 1000px) {

  .line-1{

    font-size: 4vw;

  }
  
}


/* Animation */
.anim-typewriter{
animation: typewriter 4s steps(44) 1s 1 normal both,
           blinkTextCursor 500ms steps(44) infinite normal;
}
@keyframes typewriter{
from{width: 0;}
to{width: 20em;}
}
@keyframes blinkTextCursor{
from{border-right-color: red;}
to{border-right-color: transparent;}
}
.tayo {

  color: red;

}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.Prism-code code, pre{

  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 14px 15px 5px 1px rgba(0, 0, 0, 0.356);
  font-family: Consolas, Monaco, 'Andale Mono', 'Lucida Console', monospace;

}

.Prism {

  margin-top: 5%;
  margin: 0 auto;
  width: 50%;
  margin-bottom: 5%;


}

@media screen and (max-width: 500px) {


  .Prism{

    width: 85%;
    padding-bottom: 10%;
    margin: 0 auto;
  }
  
}


.Projects-img {

  width: auto;
  height : 300px;
  border-radius: 20px;

}